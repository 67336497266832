

export const Media_player = [
  
];



export const nav_links = [
  { id: 1, href: "/", pathName: "Home" },
  { id: 2, href: "/", pathName: "Features" },
  { id: 4, href: "/", pathName: "Blogs" },
  { id: 5, href: "/contact-us", pathName: "Contact Us" },
  
];


