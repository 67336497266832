"use client";

import { useEffect, useRef } from "react";

const LazyImage = ({ src, alt, ...props }) => {
  const imgRef = useRef();

  useEffect(() => {
    const imgElement = imgRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove("lazy");
          lazyImage.classList.add("loaded");
          observer.unobserve(lazyImage);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (imgElement) {
      observer.observe(imgElement);
    }

    return () => {
      if (imgElement) {
        observer.unobserve(imgElement);
      }
    };
  }, []);

  return (
    <img ref={imgRef} data-src={src} alt={alt} className="lazy" {...props} />
  );
};

export default LazyImage;
