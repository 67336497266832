"use client";

import "./page.css";
import LazyImage from "./LazyImage";
import services1 from "@/Helper/images/services1.jpg";
import services2 from "@/Helper/images/services2.jpg";
import services7 from "@/Helper/images/services7.jpg";
import services3 from "@/Helper/images/services3.jpg";
import top5 from "@/Helper/images/top5.jpg";
import top7 from "@/Helper/images/top7.jpg";
import services4 from "@/Helper/images/services8.jpg";
import services5 from "@/Helper/images/services5.jpg";
import services6 from "@/Helper/images/services6.jpg";
import services8 from "@/Helper/images/services8.jpg";
import top2 from "@/Helper/images/top2.jpg";
import top3 from "@/Helper/images/top3.jpg";
import "../styles/fonts.css";
import ContactUs from "@/component/ContactUs/ContactUs";
import { useEffect } from "react";
import Footer from "@/component/Footer/Footer";

export default function Home() {
  useEffect(() => {
    const toTopBtn = document.getElementById("back-top__content--desk");
  
    toTopBtn.addEventListener("click", function (e) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        toTopBtn.classList.add("active");
      } else {
        toTopBtn.classList.remove("active");
      }
    };
  
    handleScroll();
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  return (
    
    <main className="standardnbtdemo">
      <section className="Topsection">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="item item-80">
                <div className="lefttopsection">
                  <h1>STANDARD DEMOLITION</h1>
                </div>
              </div>
              <div className="row">
                <div className="mobiledisplay">
                <div className="row">
                  <div className="col">
                  <video
                      id="videobcg1"
                      preload="auto"
                      autoPlay
                      loop
                      muted
                      volume="0"
                    >
                      <source
                        src="https://res.cloudinary.com/diao2msfj/video/upload/v1723031243/Video1_e2yr9c.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="col">
                  <div className="demoImages demovideos">
                    <video
                      id="videobcg2"
                      preload="auto"
                      autoPlay
                      loop
                      muted
                      volume="0"
                    >
                      <source
                        src="https://res.cloudinary.com/diao2msfj/video/upload/v1723031247/video2_vryzer.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6">
                  <div className="demoImages">
                    <video
                      id="videobcg1" className="VideoNone"
                      preload="auto"
                      autoPlay
                      loop
                      muted
                      volume="0"
                    >
                      <source
                        src="https://res.cloudinary.com/diao2msfj/video/upload/v1723031243/Video1_e2yr9c.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div className="servicestext mobileNT">
                      <label>
                        OUR<br></br> SERVICES
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="demoImages mobileNT">
                    <LazyImage
                      src="https://res.cloudinary.com/diao2msfj/image/upload/v1723640523/services1_cmlukc.jpg"
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                    <LazyImage
                      src={services7.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="demoImages layoutimg mobileNT">
                    <LazyImage
                      src={top5.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                    <LazyImage
                      src={services4.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy margin"
                    />
                    <LazyImage
                      src={top7.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="item item-20">
                <div className="Image1">
                  <div className="demoImages demovideos mt40">
                    <video
                      id="videobcg2" className="VideoNone"
                      preload="auto"
                      autoPlay
                      loop
                      muted
                      volume="0"
                    >
                      <source
                        src="https://res.cloudinary.com/diao2msfj/video/upload/v1723031247/video2_vryzer.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="Image1 mobileNT">
                  <div className="demoImages">
                    <LazyImage
                      src={top2.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                </div>
                <div className="Image1 mobileNT">
                  <div className="demoImages">
                    <LazyImage
                      src={top3.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Servicessection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mobilenone">
              <div className="column column-1">
                <div className="leftserviceImage">
                  <LazyImage
                    src={services1.src}
                    alt="Image of FULL DECONSTRUCTION services"
                    className="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 paddingRight">
              <div className="column column-1">
                <div className="rightcontent MoveTop">
                  <h2 >
                    FULL <br />
                    DECONSTRUCTION
                  </h2>
                  <div className="leftserviceImage mobileopen mobilefull">
                    <LazyImage
                      src={services1.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                  <p>
                    We specialize in the full deconstruction of both residential
                    and commercial structures to support large scale renovations
                    and additions. This service also applies to homes in tight
                    to reach places that are required to be removed in order to
                    support a new custom home. Our services include full guts,
                    roof removals, exterior cladding removals, and foundation
                    wall removals. We are well equipped to take on your manual
                    deconstruction project that requires more finesse than a
                    large excavator can offer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="min_height"></div>

      <section className="Servicessection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 paddingRight">
              <div className="column column-2">
                <div className="rightcontent">
                  <h2>
                    SELECTIVE
                    <br /> DEMOLITION
                  </h2>
                  <div className="leftserviceImage mobileopen">
                    <LazyImage
                      src={services6.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                  <p>
                    Every project has unique requirements and may not warrant a
                    full interior gut. Our team is adept at seeing through
                    selective demolition projects that require a tactful
                    approach that preserves certain finishes while removing
                    others.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mobilenone">
              <div className="column column-1">
                <div className="leftserviceImage">
                  <LazyImage
                    src={services6.src}
                    alt="Image of FULL DECONSTRUCTION services"
                    className="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="min_height"></div>

      <section className="Servicessection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mobilenone">
              <div className="column column-1">
                <div className="leftserviceImage">
                  <LazyImage
                    src={services3.src}
                    alt="Image of FULL DECONSTRUCTION services"
                    className="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 paddingRight">
              <div className="column column-1">
                <div className="rightcontent">
                  <h2>
                    DESIGNATED <br />
                    SUBSTANCE <br />
                    ABATEMENT
                  </h2>
                  <div className="leftserviceImage mobileopen mobilelayout">
                    <LazyImage
                      src={services3.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                  <p>
                    We specialize in the removal of asbestos, lead, cellulose,
                    mould, and other designated substances. We have built the
                    resources in-house required to tackle these surprises on our
                    job-sites to avoid downtime and keep the job moving safely.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="min_height"></div>

      <section className="Servicessection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 paddingRight">
              <div className="column column-2">
                <div className="rightcontent">
                  <h2>
                    SHORING &amp;
                    <br /> ENGINEERING
                  </h2>
                  <div className="leftserviceImage mobileopen mobileshoring">
                    <LazyImage
                      src={services2.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                  <p>
                    Sometimes structures aren’t built the way we thought and
                    require engineered shoring and bracing to support the
                    deconstruction process. Our team has the in-house
                    capabilities to see through this scope of work in order to
                    complete the job safely with minimal downtime.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mobilenone">
              <div className="column column-1">
                <div className="leftserviceImage">
                  <LazyImage
                    src={services2.src}
                    alt="Image of FULL DECONSTRUCTION services"
                    className="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="min_height"></div>

      <section className="Servicessection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mobilenone">
              <div className="column column-1">
                <div className="leftserviceImage">
                  <LazyImage
                    src={services5.src}
                    alt="Image of FULL DECONSTRUCTION services"
                    className="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 paddingRight">
              <div className="column column-1">
                <div className="rightcontent">
                  <h2>
                    ONE DAY
                    <br /> SERVICE &amp;
                    <br /> SITE MAINTENANCE
                  </h2>
                  <div className="leftserviceImage mobileopen">
                    <LazyImage
                      src={services5.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                  <p>
                    Our team is here to get the job done. While we specialize in
                    large scale demolition and deconstruction, we are always
                    playing a supporting role for our contractor clients who
                    have smaller jobs such as single bathroom demolition
                    projects or site cleanup projects in order to keep the site
                    moving. No job is too small in the face of a valued working
                    relationship.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="min_height"></div>

      <div className="container">
        <h3 className="commontitle">
          WHO WE <br />
          ARE
        </h3>
      </div>

      <div className="min_height"></div>

      <section className="Servicessection">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="column column-1">
                <div className="rightcontent_bottom">
                  <p>
                    Juwan Codrington started Standard Demolition in 2018 after
                    working for several years as the lead operations and site
                    maintenance manager for Sensus Design Studio. Throughout his
                    young career, Juwan has seen through over 700,000 square
                    feet of residential demolition projects.
                  </p>
                  <div className="leftserviceImage FootMobile">
                    <LazyImage
                      src={services4.src}
                      alt="Image of FULL DECONSTRUCTION services"
                      className="lazy"
                    />
                  </div>
                  <p>
                    After being formally educated as a Mechanical Engineer,
                    Juwan decided to get his hands dirty in a setting that he
                    enjoyed—the action of the field. His energy continues to be
                    the impetus behind the relentlessly consistent pace of our
                    team, and his excitement for demolition is the driving force
                    behind the relationships we foster.
                  </p>
                  <p>
                    To this day, Juwan visits every project we are involved with
                    to oversee the careful and tactical work his team is known
                    for. His biggest principle continues to be that everyone
                    involved in the operation has a responsibility to help get
                    the job done. This mentality transcends labor roles, as even
                    he is willing to jump in and help when needed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mobilenone">
              <div className="column column-1">
                <div className="leftserviceImage ">
                  <LazyImage
                    src={services4.src}
                    alt="Image of FULL DECONSTRUCTION services"
                    className="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="min_height"></div>
      </section>

      <div id="back-top__content--desk" className="back-top">
        &#8679;
      </div>
      <ContactUs />
      <Footer />
    </main>
  );
}
