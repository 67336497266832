/** @format */

"use client";
import React, { useEffect, useMemo } from "react";
import "./page.css";

const ContactForm = () => {
  const memoizedIframe = useMemo(
    () => (
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.5743772329015!2d-79.32592382381857!3d43.74016797109821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cd8f8fe70335%3A0x6c85643e394bff6d!2s1315%20Lawrence%20Ave%20E%20%23510%2C%20North%20York%2C%20ON%20M3A%203R3%2C%20Canada!5e0!3m2!1sen!2sin!4v1723186033463!5m2!1sen!2sin"
          width="95%"
          height="308"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    ),
    []
  );

  useEffect(() => {
    
    const loadHubSpotForm = () => {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "39930342",
            formId: "c599e65a-a8bc-4e28-ac82-cc8116fb417d",
            target: "#hubspotFormContainer",
          });
        }
      };
      document.body.appendChild(script);
    };

    loadHubSpotForm();
  }, []);

  return (
    <section className="ContactSection">
      <div className="container containerxxl">
        <div className="topsection">
          <h3>Contact us</h3>
          <p>
            For more information about our services, or to request a quote, give
            us a call! You can also fill<br /> out our contact form, and we will get
            back to you within one business day.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="LeftsideAddress">
              <h4>LOCATION</h4>
              <label>
                1315 Lawrence Avenue East Unit 510
                <br />
                Toronto, Ontario M3A 3R3
                <br />
                647-993-5911
              </label>
              {memoizedIframe}
            </div>
          </div>
          <div className="col-md-6" id="Pointtocontactus">
            <div className="Letstalk">
              <div className="title_Section"></div>
              <div id="hubspotFormContainer"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="min_height"></div>
    </section>
  );
};

export default ContactForm;
